<template>
    <div>   
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12">
                    <br>
                    <v-card > 
                        <mbs-page-tab 
                            :items="MY_LINKS"/> 
                        <mbs-item-manager
                            :top_label="false"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM"
                            :field_items="FIELD_ITEMS"
                            :header_items="HEADER_ITEMS"
                            :header_items2="HEADER_ITEMS2"
                            :data_items="DATA_ITEMS"   
                            :path_item="PATH_ITEM"  
                            :select_items="SELECT_ITEMS"  

                            :table_actions="table_actions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
    </div> 
</template>

<script> 
    import DATA from '../../../../plugins/DATA'
    let PAGE_NAME = DATA.ITEMS.SUPPLIERS.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.SUPPLIERS.names,
                NAME_ITEM:DATA.ITEMS.SUPPLIERS.name,
                VALUE_ITEMS:DATA.ITEMS.SUPPLIERS.values,
                VALUE_ITEM:DATA.ITEMS.SUPPLIERS.value,  

                FIELD_ITEMS:[
                    {type:"text",value:"name",label:"Full Name",required:true,sm:12,md:6},   
                    {type:"autocomplete",value:"category_code",label:"Select Customer Group",select:{
                        name:'SupplierCategories',
                        value:'code',
                        text:'name'
                    },required:true,sm:12,md:6,item_select:{
                        icon:"add",
                        action:"Add ",
                        name:"Categories",
                        link:"/office/people/suppliers/categories",
                        company_link:true
                    }}, 
                    {type:"number",value:"code",label:"Code",required:true,sm:12,md:6}, 
                    {type:"text",value:"phone_number",label:"Phone Number",required:true,sm:12,md:6},   
                    {type:"text",value:"email_address",label:"Email Address",required:true,sm:12,md:6},   
                    {type:"text",value:"location_address",label:"Location Address",required:false,sm:12,md:6},     
                    {type:"textarea",value:"description",label:"Description",required:true,sm:12,md:12},   
                ],     
                table_actions:[
                    {type:"btn",action:"add_item",color:"secondary", outlined:false,  text:"Add Supplier"}, 
                    {type:"btn",action:"manage_supplier_categories",color:"secondary", outlined:false,  text:"Manage Categories"}, 
                    // {type:"btn",action:"received_stock",color:"secondary",  text:"Received Stock"}, 
                    // {type:"btn",action:"return_stock",color:"secondary",  text:"Return Stock"}, 
                    // {type:"btn",action:"transfer_stock",color:"secondary",  text:"Transfer Stock"}, 
                ] 
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            }, 
            us(){
                return this.$store.getters.getUser
            },
            ud(){
                return this.$store.getters.getUserDetails
            },
            loading(){  
                return this.MBS.actions.loading
            },
            processes(){  
                return this.MBS.actions.processes
            }, 
            responses(){  
                return this.MBS.actions.responses
            },  
            /////////
            selectedCompany(){  
                return this.$store.getters.getSelectedCompany
            },  
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("people",2,true) 
                return thisLinks
            }, 
            PATH_ITEM(){  
                let selectedCompany = this.selectedCompany
                let company_key = selectedCompany?selectedCompany.key:null
                if(!company_key){
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:"ERROR",
                        text:"No company is selected. Please, make sure you select the company.",
                        btnYes:"SELECT COMPANY",
                        action:{
                            code:PAGE_NAME+"=SELECT-COMPANY",
                        }
                    })
                    return null
                } 
                let P_DATA = (DATA.APP.SERVER+'/'+DATA.ITEMS.COMPANY_DATA.values).toUpperCase()+'/'+company_key+'/' 
                let P_SUPPLIERS = P_DATA+'SETTINGS/'+(DATA.ITEMS.SUPPLIERS.values.toUpperCase())+'/'
 
                return P_SUPPLIERS
            }, 
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"code",align:"center",show:true},     
                    {id:0,name:"Name",value:"name",show:true},     
                    {id:0,name:"Phone",value:"phone_number",show:true},       
                    {id:0,name:"Email",value:"email_address",show:true},       
                    {id:0,name:"Location",value:"location_address",show:true},     
                    {id:0,name:"create",value:'created_at',show:true},    
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"Edit Supplier",action:"edit_item",icon:"edit"}, 
                        // {name:"View Items",action:"view_order_items",icon:"mdi-eye"}, 
                    ]}, 
                ]
            },
            HEADER_ITEMS2(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:true},  
                    {id:0,name:"name",show:true}, 
                    {id:0,name:"code",align:"center",show:true},      
                    {id:0,name:"action",align:"right",show:true,item:[  
                        {name:"Edit "+this.NAME_ITEM,action:"edit_item",icon:"mdi-pencil"},
                        {name:"Delete "+this.NAME_ITEM,action:"delete_item",icon:"mdi-delete"},
                    ]}, 
                ]
            }, 
            DATA_ITEMS(){ 
                return this.$store.getters.getSuppliers
            },    
            SupplierCategories(){
                return this.$store.getters.getCustomerGroups
            },    
            SELECT_ITEMS(){ 
                let SupplierCategories = this.SupplierCategories  
                return {
                    SupplierCategories:SupplierCategories
                }
            }, 
        },
        methods: { 
            TABLE_ACTION(action,item){
                try {
                    let route = this.route
                    let fullPath = route?route.fullPath:null
                     console.log(action,'action');
                    if (action == "order_stock") {
                        this.MBS.actions.go(fullPath+"/order")
                    }else if (action == "received_stock") {
                        this.MBS.actions.go(fullPath+"/receive")
                    }else if (action == "return_stock") {
                        this.MBS.actions.go(fullPath+"/return")
                    }else if (action == "transfer_stock") {
                        this.MBS.actions.go(fullPath+"/transfer")
                    }else if (action == "manage_supplier_categories") {
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/office/people/suppliers/categories",true))
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_ITEM,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                             
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            PAGE_PERMISSION(){
                try {
                    let us
                    if (!us) {
                        this.MBS.actions.go("/")
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAGE_PERMISSION',
                        page:PAGE_NAME, 
                    })
                }
            },
    
        },
        watch: { 
            us(v){
                this.PAGE_PERMISSION()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
    }
</script>
 
